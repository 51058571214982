import React from 'react';
import banner_one from '../assests/pics/banner_one.png'

const Nav = () => {
  return (
    <>
    <img src={banner_one} alt="Delivery Banner" className='banner-one'/>
    </>
  )
}

export default Nav